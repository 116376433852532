import { VERIFICAR_CONSISTENCIA_DA_INSCRICAO_SUCESSO, VERIFICAR_SE_POSSUI_INFORMACOES_MINIMAS_PREENCHIDAS_SUCESSO } from '../actions/actionTypes';
import initialState from './initialState.reducer';

export default (state = initialState.menu, action) => {
  switch (action.type) {
  case VERIFICAR_CONSISTENCIA_DA_INSCRICAO_SUCESSO:
    return { ...state, pendenciasDaInscricao: action.pendenciasDaInscricao };
  case VERIFICAR_SE_POSSUI_INFORMACOES_MINIMAS_PREENCHIDAS_SUCESSO:
    return { ...state, possuiInformacoesMinimasPreenchidas: action.possuiInformacoesMinimasPreenchidas };
  default:
    return state;
  }
};