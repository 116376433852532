import React, { Component } from 'react';
import '../../estilos/estilos.scss';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

export default class AvisoDeInconsistencia extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mensagemDeAlerta: '',
      inconsistencias: []
    };
  }

  shouldComponentUpdate(proximasProps) {
    let inconsistenciasAtuais = this.state.inconsistencias ? this.state.inconsistencias : [];
    let inconsistenciasAtualizadas = proximasProps.inconsistencias ? proximasProps.inconsistencias : [];
    let statusDeVisibilidadeAtual = this.props.visivel;
    let statusDeVisibilidadeAtualizado = proximasProps.visivel;
    let mensagemDeAlertaAtual = this.state.mensagemDeAlerta;
    let mensagemDeAlertaAtualizada = proximasProps.mensagemDeAlerta;

    if (statusDeVisibilidadeAtual == statusDeVisibilidadeAtualizado
      && inconsistenciasAtuais.length == inconsistenciasAtualizadas.length
      && inconsistenciasAtuais.every((elemento, indice) => elemento == inconsistenciasAtualizadas[indice])
      && (mensagemDeAlertaAtual == mensagemDeAlertaAtualizada))
      return false;

    return true;
  }

  componentDidUpdate() {
    this.setState({
      mensagemDeAlerta: this.props.mensagemDeAlerta,
      inconsistencias: this.props.inconsistencias
    });
  }

  render() {
    let classe = this.props.visivel ? 'aviso-de-inconsistencia_visivel' : '';
    let inconsistencias = this.props.inconsistencias || [];
    let mensagemDeAlerta = this.state && this.state.mensagemDeAlerta
      ? this.state.mensagemDeAlerta
      : '';
    return (
      <div id="aviso-de-inconsistencia" tabIndex="0" className={`aviso-de-inconsistencia ${classe}`}>
        <h4 className="aviso-de-inconsistencia__titulo"> 
          <i className="fal fa-exclamation-triangle" /> Atenção!
        </h4>
        <p className="aviso-de-inconsistencia__paragrafo u-margem-superior-pequena">
          {mensagemDeAlerta}
        </p>
        {inconsistencias.length > 0 &&
          <ul className="aviso-de-inconsistencia__lista">
            {inconsistencias.map((inconsistencia, index) =>
              <li key={index}>{ReactHtmlParser(inconsistencia)}</li>
            )}
          </ul>}
      </div>
    );
  }
}

AvisoDeInconsistencia.propTypes = {
  inconsistencias: PropTypes.array,
  mensagemDeAlerta: PropTypes.string,
  visivel: PropTypes.bool
};