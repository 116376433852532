import * as actionTypes from './actionTypes';
import { apiDaInscricao } from '../servicos/axios';
import { ACESSO_ADMINISTRATIVO } from '../helpers/tiposDeAcesso';

export const sucessoAoVerificarConsistenciaDaInscricao = pendenciasDaInscricao => ({
  type: actionTypes.VERIFICAR_CONSISTENCIA_DA_INSCRICAO_SUCESSO,
  pendenciasDaInscricao
});

export const sucessoAoVerificarSePossuiInformacoesMinimasPreenchidas = possuiInformacoesMinimasPreenchidas => ({
  type: actionTypes.VERIFICAR_SE_POSSUI_INFORMACOES_MINIMAS_PREENCHIDAS_SUCESSO,
  possuiInformacoesMinimasPreenchidas
});

export const buscarInformacoesDoMenu = (idDaInscricao) =>
  async (dispatch) => {
    let informacoesDoMenu;
    if (ACESSO_ADMINISTRATIVO == localStorage.getItem('tipo_acesso'))
      informacoesDoMenu = await apiDaInscricao().get(`/menudeinscricao/${idDaInscricao}/inconsistencias`);
    else
      informacoesDoMenu = await apiDaInscricao().get(`/menu/${idDaInscricao}/informacoes`);

    const { data: { consistenciaDaInscricao: { pendenciasDaInscricao }, possuiInformacoesMinimasPreenchidas } } = informacoesDoMenu;
    dispatch(sucessoAoVerificarConsistenciaDaInscricao(pendenciasDaInscricao));
    dispatch(sucessoAoVerificarSePossuiInformacoesMinimasPreenchidas(possuiInformacoesMinimasPreenchidas));
  };