import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import initialState from '../../reducers/initialState.reducer';
import BarraDeNavegacao from '../../componentes/BarraDeNavegacao';
import ContainerConteudo from '../../componentes/ContainerConteudo';
import AvisoDeInconsistencia from '../../componentes/AvisoInconsistencia';
import ItemMenu from './componentes/ItemMenu';
import IPretendente from '../../interfaces/IPretendente';
import IDependente from '../../interfaces/IDependente';
import IInformacoesDaFamilia from '../../interfaces/IInformacoesDaFamilia';
import IImovelProprio from '../../interfaces/IImovelProprio';
import { Header, Logos } from '../../componentes';
import { obterDadosAutenticacao } from '../../servicos/autenticacao';
import { buscarInformacoesDoMenu } from '../../actions/menu.actions';
import IMoradia from '../../interfaces/IMoradia';
import { consultarInscricao } from '../../servicos/MenuDeInscricao';
import { carregarInscricao } from '../../actions/inscricao.actions';
import { ehFormularioSomenteLeitura as ehFormularioSomenteLeituraAction } from '../../actions/ehFormularioSomenteLeitura.actions';
import { validarPermissaoParaEdicao } from '../../servicos/inscricao';
import { deveCarregarInscricaoNaMemoria } from '../../actions/deveCarregarInscricaoNaMemoria.actions';
import { useHistory } from 'react-router-dom';
import IMenu from '../../interfaces/IMenu';

const MenuDeInscricao = (props:any) => {
  const dispatch = useDispatch();
  const menu = useSelector((state: typeof initialState) => state.menu) as IMenu;
  const inscricao = useSelector((state: typeof initialState) => state.inscricao);
  const dadosTitular = useSelector((state: typeof initialState) => state.dadosTitular) as IPretendente;
  const dadosConjuge = useSelector((state: typeof initialState) => state.dadosConjuge) as IPretendente;
  const dependentes = useSelector((state: typeof initialState) => state.dependentes) as IDependente[];
  const imoveis = useSelector((state: typeof initialState) => state.imoveis) as IImovelProprio[];
  const moradia = useSelector((state: typeof initialState) => state.moradia) as IMoradia;
  const informacoesDaFamilia = useSelector((state: typeof initialState) => state.informacoesDaFamilia) as IInformacoesDaFamilia;
  const ehAcessoAdministrativo: boolean = useSelector((state: typeof initialState) => state.ehAcessoAdministrativo);
  const carregarInscricaoNaMemoria: boolean = useSelector((state: typeof initialState) => state.deveCarregarInscricaoNaMemoria);
  const ehFormularioSomenteLeitura = useSelector((state: typeof initialState) => state.ehFormularioSomenteLeitura) as boolean;

  const conjugeEstaHabilitado = dadosTitular?.estadoCivil?.toUpperCase() === 'CASADO' || dadosTitular?.uniaoEstavel === true;
  const titularEstaPreenchido = dadosTitular && dadosTitular?.id > 0;
  const conjugeEstaPreenchido = !!dadosConjuge && dadosConjuge.id > 0;
  const nomeDoConjuge = !!dadosConjuge && (dadosConjuge.nome || 'Cônjuge');
  const quantidadeDeDependentes = dependentes ? dependentes.length : 0;
  const quantidadeDeImoveis = imoveis.length;
  const informacoesDaFamiliaEstaPreenchido = !!informacoesDaFamilia?.tipoDeImovelDeInteresse;

  const pendenciasDaInscricao = ehAcessoAdministrativo ? menu.pendenciasDaInscricao : 
    menu.pendenciasDaInscricao.filter(pendencia => !pendencia.ehExclusivaDoAcessoAdministrativo);
  const inconsistencias = pendenciasDaInscricao?.map(item => item.mensagens).flat() || [];
  const inscricaoPossuiInconsistencias = inconsistencias.length > 0;
  const inscricaoEstaConsistente = menu?.possuiInformacoesMinimasPreenchidas && !inscricaoPossuiInconsistencias;
  const enderecoEstaPreenchido = moradia.endereco?.cep && moradia.endereco?.logradouro &&
    moradia.endereco?.numero && moradia.endereco?.bairro;

  const todosOsDadosObrigatoriosEstamPreenchidos = inscricaoEstaConsistente && titularEstaPreenchido &&
    enderecoEstaPreenchido && informacoesDaFamiliaEstaPreenchido &&
    (!conjugeEstaHabilitado || (conjugeEstaHabilitado && conjugeEstaPreenchido));

  const mensagemDeAlerta = obterMensagemDeAlerta();      
  
  useEffect(() => {
    obterInformacoesDoMenu();
  }, []);

  const history = useHistory();
  useEffect(()=> {
    if (props.ehAcessoPublico) {
      const currentPath = history.location.pathname;
      const newUrl = `${currentPath}?publico`;
      history.push(newUrl);
    }
  }, []);

  function obterInformacoesDoMenu() {
    let dados = obterDadosAutenticacao();

    if (carregarInscricaoNaMemoria || inscricao.id == 0) {
      //@ts-ignore
      const idDaInscricao = parseInt(dados.idDaInscricao);
      consultarInscricao(idDaInscricao).then(resposta => {
        resposta = resposta.data as IPretendente;
        dispatch(carregarInscricao(resposta));
        validarPermissaoParaEdicaoDaInscricao(idDaInscricao);
        dispatch(deveCarregarInscricaoNaMemoria(false));
      }).catch();
    }
    dispatch(buscarInformacoesDoMenu(dados.idDaInscricao));
  }

  function obterMensagemDeAlerta() {
    if(ehFormularioSomenteLeitura)
      return 'A inscrição não pode ser alterada. Para mais informações entrar em contato com a AGEHAB.';
    if (inscricaoPossuiInconsistencias)
      return `A ${!ehAcessoAdministrativo ? 'sua' : ''} inscrição apresenta dados incompletos ou inconsistentes no momento. Resolva corrigindo os campos abaixo:`;
    if (!menu?.possuiInformacoesMinimasPreenchidas)
      return `A ${!ehAcessoAdministrativo ? 'sua' : ''} inscrição não está totalmente preenchida. Finalize entrando no item Programas Habitacionais`;
    return '';
  }

  function posicionarTelaComFocoNoAvisoDeInconsistencias() {
    let componenteAviso: HTMLElement | null = document.querySelector('#aviso-de-inconsistencia');
    componenteAviso && componenteAviso?.focus();
  }

  const validarPermissaoParaEdicaoDaInscricao = async (idDaInscricao: Number) => {
    if (!idDaInscricao) return;
    const permitidoEdicao = await validarPermissaoParaEdicao(idDaInscricao);
    dispatch(ehFormularioSomenteLeituraAction(!permitidoEdicao));
  };

  return (
    <>
      {!ehAcessoAdministrativo && (
        // @ts-ignore
        <Header subTitulo="Menu da minha inscrição" />
      )}
      {ehAcessoAdministrativo && (
        <BarraDeNavegacao titulo="Menu de Inscrição"
          comBotaoVoltar={true}
          acaoBotaoVoltar={() => dispatch(push('/listagem-de-inscricoes'))}
          comBotaoADireita={false} />
      )}
      <div className="menu-de-inscricao">
        <div className="menu-de-inscricao__container">
          <h3 className="menu-de-inscricao__titulo">Preencha todas as categorias abaixo para concluir seu cadastro</h3>
          <ContainerConteudo className={''}>
            <AvisoDeInconsistencia
              inconsistencias={!ehFormularioSomenteLeitura ? inconsistencias : []}
              mensagemDeAlerta={mensagemDeAlerta}
              visivel={!inscricaoEstaConsistente} />
            <ItemMenu
              habilitado
              icone="fa-user"
              link={{
                href: '/dados-titular',
                texto: 'Titular'
              }}
              descricao={titularEstaPreenchido ? dadosTitular.nome : 'Não preenchido'}
              descricaoMaiuscula
              itemDestaque={false} />
            <ItemMenu
              habilitado={conjugeEstaHabilitado}
              icone="fa-user-friends"
              link={{
                href: '/dados-conjuge',
                texto: 'Cônjuge'
              }}
              descricao={conjugeEstaHabilitado ?
                conjugeEstaPreenchido ? nomeDoConjuge : 'Não preenchido' :
                'Sem cônjuge'}
              descricaoMaiuscula={true}
              itemDestaque={false} />
            <ItemMenu
              habilitado
              icone="fa-users"
              link={{
                href: '/dependentes',
                texto: 'Dependentes'
              }}
              descricao={quantidadeDeDependentes ?
                `${quantidadeDeDependentes} Dependente(s) cadastrado(s)` :
                'Nenhum dependente cadastrado'} itemDestaque={false} descricaoMaiuscula={false} />
            <ItemMenu
              habilitado
              icone="fa-map-marker-alt"
              link={{
                href: '/endereco',
                texto: 'Endereço'
              }} itemDestaque={false} descricaoMaiuscula={false} />
            <ItemMenu
              habilitado
              icone="fa-home"
              link={{
                href: '/imoveis',
                texto: 'Imóveis próprios'
              }}
              descricao={quantidadeDeImoveis ?
                `${quantidadeDeImoveis} imóveis cadastrados` :
                'Nenhum imóvel próprio cadastrado'} itemDestaque={false} descricaoMaiuscula={false} />
            <ItemMenu
              habilitado
              icone="fa-credit-card"
              link={{
                href: '/programas-sociais',
                texto: 'Programas sociais'
              }} itemDestaque={false} descricaoMaiuscula={false} />
            <ItemMenu
              habilitado
              icone="fa-layer-group"
              link={{
                href: '/programas-habitacionais',
                texto: 'Programas habitacionais'
              }} itemDestaque={false} descricaoMaiuscula={false} />
            {(todosOsDadosObrigatoriosEstamPreenchidos || ehAcessoAdministrativo) ?
              <ItemMenu
                habilitado
                icone="fa-print"
                link={{
                  href: ehAcessoAdministrativo ? 'comprovante' : '/resumo-da-inscricao',
                  texto: 'Imprimir comprovante da inscrição'
                }}
                itemDestaque descricaoMaiuscula={false} />
              :
              <ItemMenu
                icone="fa-print"
                link={{
                  href: '#',
                  texto: 'Imprimir comprovante da inscrição',
                  onClick: () => posicionarTelaComFocoNoAvisoDeInconsistencias()
                }}
                itemDestaque descricaoMaiuscula={false} habilitado={false} />
            }
          </ContainerConteudo>
        </div>
        {!ehAcessoAdministrativo &&
          <footer className="pagina-menu__footer">
            <Logos />
          </footer>}
      </div>
    </>
  );
};

export default MenuDeInscricao;