import IDemandaEspecifica from '../../interfaces/IDemandaEspecifica';
import IInformacoesDaFamiliaCampos, { IInformacoesDaFamiliaErroCampos } from '../../interfaces/IInformacaoDaFamiliaValidacao';
import IInformacoesDaFamilia from '../../interfaces/IInformacoesDaFamilia';

let _informacoesDaFamilia: IInformacoesDaFamilia;
let _obrigarQuestaoDoProjetoEmendasCidades: boolean;
let _obrigarDemandasEspecificas: boolean;
let _demandasEspecificas: IDemandaEspecifica[];

const obrigatorio = (valor: string | boolean) => {
  if (valor === undefined || valor === '')
    return true;
};

const autorizaConsultaNosOrgaosDeProtecaoObrigatorio = () => {
  if (_informacoesDaFamilia.temInteresseEmFinanciamento && _informacoesDaFamilia.autorizaConsultaNosOrgaosDeProtecaoAoCredito == null) {
    return true;
  }
};

const temInteresseNoEmendasCidades = () => {
  if(_obrigarQuestaoDoProjetoEmendasCidades && _informacoesDaFamilia.temInteresseNoEmendasCidades == null)
    return true;
};

const obrigatorioQuandoExibirDemandasEspecificas = () => {

  if (_demandasEspecificas != undefined && _demandasEspecificas.length > 0 && _obrigarDemandasEspecificas){
    const listaIdDasDemandas = _demandasEspecificas.map(demanda => demanda.id);
    const listaIdDasDemandasDaInscricao  = _informacoesDaFamilia.demandasEspecificasDaInscricao?.map(demanda => demanda.demandaEspecifica.id);
    return !listaIdDasDemandas.every(id => listaIdDasDemandasDaInscricao?.includes(id)); 
  }
};

const validacoes: IInformacoesDaFamiliaCampos = {
  autorizaConsultaNosOrgaosDeProtecaoAoCredito: [autorizaConsultaNosOrgaosDeProtecaoObrigatorio],
  temInteresseEmFinanciamento: [obrigatorio],
  temInteresseNoLoteUrbanizado: [obrigatorio],
  tipoDeImovelDeInteresse: [obrigatorio],
  temInteresseNoBonusMoradia: [obrigatorio],
  temInteresseNoEmendasCidades: [temInteresseNoEmendasCidades],
  demandasEspecificasDaInscricao: [obrigatorioQuandoExibirDemandasEspecificas]
};

const aplicarFuncoesDeValidacao = (contexto: IInformacoesDaFamilia, campo: string, valor: string) => {
  //@ts-ignore
  const funcoes = validacoes[campo] || [];
  _informacoesDaFamilia = contexto;
  const retornosComErro = funcoes.map((f: Function) => f.call(contexto, valor)).filter((r: any) => !!r);

  return retornosComErro[0];
};

const validarFormulario = (informacoesDaFamilia: IInformacoesDaFamilia, obrigarQuestaoDoProjetoEmendasCidades: boolean, 
  demandasEspecificas: IDemandaEspecifica[], obrigarDemandasEspecificas: boolean): IInformacoesDaFamiliaErroCampos => {
  _obrigarQuestaoDoProjetoEmendasCidades = obrigarQuestaoDoProjetoEmendasCidades;
  _demandasEspecificas = demandasEspecificas;
  _obrigarDemandasEspecificas = obrigarDemandasEspecificas;

  const errosValidacao: any = Object.keys(validacoes).reduce((erros, campo) => {
    //@ts-ignore
    const erro = aplicarFuncoesDeValidacao(informacoesDaFamilia, campo, informacoesDaFamilia[campo]);
    if (erro)
      return { ...erros, [campo]: erro };

    return erros;
  }, {});
  return errosValidacao;
};
export default { validarCampo: aplicarFuncoesDeValidacao, validarFormulario };